<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
        <router-link to="/admin/slideshow/lists">
          Quản lý Slideshow
        </router-link>
      </li>

      <li class="breadcrumb-item">Cập nhật slideshow</li>
    </ol>

    <form method="post" @submit.prevent="mySubmit" id="sendform">
      <input type="hidden" :value="from.id" name="id" />
      <div class="container">
        <!-- Tab panes -->
        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Thông tin </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            >Tên<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                            v-model="from.name"
                          />
                          <p class="text-danger">{{ error.name }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="links"
                            >Links<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="links"
                            class="form-control"
                            v-model="from.links"
                          />
                          <p class="text-danger">{{ error.links }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="picturefile"
                            >Hình ảnh đại diện<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <input
                            type="file"
                            accept="image/*"
                            name="picturefile"
                            class="form-control main_picture"
                            @change="onPicturefile"
                          />

                          <div class="view_picture_one" v-if="img_one != ''">
                            <img :src="img_one" style="max-width: 400px" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              v-if="!is_load"
            >
              <i class="fad fa-save"></i> Save
            </button>
            <h5 v-else>Loading...</h5>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      img_one: "",
      from: {},
      id: this.$route.params.id,
      is_load: false,
      error: {
        name: "",
        links: "",
      },
      status_img_one: false,
    };
  },
  mounted() {
    axios
      .get(
        window.my_api +
          "api/slideshow/get-slideshow-by-id?slideshowId=" +
          this.id,
          {
              headers: window.my_header
          }
      )
      .then((res) => {
        if (res.status == 200) {
          this.from = res.data.data;
          this.img_one = window.my_url + "/" + res.data.data.picture;
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      })
      .catch(() => {
        alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
      });
  },

  methods: {
    alertError() {
      let tt = "";
      for (let i = 0; i < Object.values(this.error).length; i++) {
        if (Object.values(this.error)[i]) {
          tt =
            tt +
            "<ul style='text-align:left;'><li>" +
            Object.values(this.error)[i] +
            "</li></ul>";
        }
      }
      return tt;
    },
    mySubmit: function () {
      let myForm = document.getElementById("sendform");
      let formData = new FormData(myForm);
      for (var pair of formData) {
        if (pair[0] == "name") {
          if (pair[1] == "") {
            this.error.name = "Vui lòng nhập tên.";
          } else {
            this.error.name = "";
          }
        }

        if (pair[0] == "links") {
          if (pair[1] == "") {
            this.error.links = "Vui lòng nhập links.";
          } else {
            this.error.links = "";
          }
        }
        if (pair[0] == "picturefile") {
          if (pair[1].name == "") {
            this.status_img_one = true;
          }
        }
      }
      if (this.error.name == "" && this.error.links == "") {
        this.is_load = true;
        if (this.status_img_one) {
          formData.delete("picturefile");
          formData.append("picture", this.from.picture);
        }
        axios
          .post(
            window.my_api + "api/slideshow/create-or-update-slideshow",
            formData,
            {
              headers: window.my_header
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.$swal.fire({
                position: "top-end",
                icon: "success",
                title: "Cập nhật thành công.",
                showConfirmButton: false,
                timer: 1500,
              });
               this.$router.go(this.$router.currentRoute);
              document.getElementById("sendform").reset();
            } else {
              alert("Cập nhật không thành công, vui lòng liên hệ admin");
            }
            this.is_load = false;
          });
      } else {
        this.$swal.fire({
          icon: "warning",
          html: this.alertError(),
        });
      }
    },
    onPicturefile(e) {
      let _this = this;
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        _this.img_one = reader.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>